<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo-agropula-with-slogan.png"
              class="max-h-150px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923;"
          >
            Nagrody kiełkują z każdym punktem
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Witaj!
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Wprowadź numer karty lojalnościowej</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="cardNo"
                    ref="cardNo"
                    v-model="form.cardNo"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Wyślij kod
                </button>
              </div>
              <span class="text-muted font-weight-bold font-size-h4"
                >Jesteś nowy?
                <a
                  id="kt_login_signup"
                  href="https://agroas.pl/agropula/formularz-rejestracji"
                  class="text-primary font-weight-bolder"
                  target="_blank"
                  >Rejestracja</a
                >
              </span>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Witaj!
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Numer karty lojalnościowej</label
                >
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="rCardNo"
                    ref="rCardNo"
                    disabled
                    v-model="form.cardNo"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Wprowadź kod autoryzacyjny</label
                >
                <div
                  id="example-input-group-3"
                  label=""
                  label-for="example-input-3"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="token"
                    ref="token"
                    v-model="form.token"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Zaloguj
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a
            href="https://agroas.pl/fileadmin/user_upload/upload/Agropula/Regulamin-Serwisu-Internetowego-AGROPULA-pl.pdf"
            target="_blank"
            class="text-primary font-weight-bolder font-size-h5"
            >Regulamin</a
          >
          <a
            href="https://agroas.pl/fileadmin/user_upload/upload/Agropula/Polityka-Prywatnosci-AGROPULA.pdf"
            target="_blank"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Polityka Prywatności</a
          >
          <a
            href="https://agroas.pl/agropula/zglos-problem"
            target="_blank"
            class="text-warning ml-10 font-weight-bolder font-size-h5"
            >Zgłoś problem</a
          >
        </div>
        <div class="services">
          Korzystając z serwisu, akceptujesz regulamin serwisu
          <b>www.agropula.pl</b> oraz politykę prywatności i plików cookies.
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
.services {
  color: #908f8f;
  font-style: italic;
  text-align: justify;
  padding-top: 15px;
  font-size: 10px;
}
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, AUTHORISE } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        cardNo: "",
        token: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.cardNoForm = formValidation(signin_form, {
      fields: {
        cardNo: {
          validators: {
            notEmpty: {
              message: "Numer karty jest wymagany",
            },
            blank: {},
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.cardNoForm.on("core.form.valid", () => {
      const cardNo = this.$refs.cardNo.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, cardNo)
        .then((response) => {
          this.cardNo = response.data;
          this.state = "signup";
        })
        .catch(() => {
          this.cardNoForm
            .updateValidatorOption("cardNo", "blank", "message", this.errors)
            .updateFieldStatus("cardNo", "Invalid", "blank");
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.cardNoForm.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Proszę wprowadzić nr karty",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.tokenForm = formValidation(signup_form, {
      fields: {
        rCardNo: {
          validators: {
            notEmpty: {
              message: "Numer karty jest wymagany",
            },
            blank: {},
          },
        },
        token: {
          validators: {
            notEmpty: {
              message: "Token jest wymagany",
            },
            blank: {},
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.tokenForm.on("core.form.valid", () => {
      const cardNo = this.$refs.rCardNo.value;
      const token = this.$refs.token.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send token request
      this.$store
        .dispatch(AUTHORISE, {
          cardNo: cardNo,
          token: token,
        })
        .then((response) => {
          if (response.role === "ROLE_USER") {
            this.$router.push({ name: "dashboard" });
          } else if (response.role === "ROLE_ADMIN") {
            this.$router.push({ name: "order-admin" });
          }
        })
        .catch(() => {
          this.tokenForm
            .updateValidatorOption("token", "blank", "message", this.errors)
            .updateFieldStatus("token", "Invalid", "blank");
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.tokenForm.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Proszę wprowadzić sms token",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
